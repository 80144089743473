@import '../common-mixin.scss';

.rcOddsTable {
    /* width: 828px; */
    flex: 1;
    border-radius: 5px;
    /* overflow-x: auto; */
}

.qin-odds-slide-container {
    // border-radius: 6px;
    overflow: hidden;
}

.rcOddsTable header {
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
}

.rcOddsTable header.HOME, .rcOddsTable header.PWIN, .rcOddsTable header.HOME_RESULTS, .rcOddsTable header.JTCOMBO {
    border-radius: 0 0 4px 4px;
}

.rcOddsTable header > .rcTitle {
    /* height: 40px; */
    background-color: #122c68;
    color: white;
    border-radius: 4px 4px 0 0;
    padding: 8px 12px;
    /* padding-left: 20px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    gap: 12px;
    min-height: 36px;
}
.rcTitleControl{
    min-width: 262px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 6px 12px;
    flex: auto;
    > *:not(:first-child){
        align-items: center;
    }
}

.RESULTS .rcTitleControl, .HOME .rcTitleControl{
    display: none;
}

.rcOddsTable header > .rc-page-rcTitle > #mtgInfoDV {
    flex: auto;
}

.rcOddsTable header > .rc-page-rcTitle > .right {
    flex-basis: auto;
}

.rcOddsTable header > .rcTitle .raceCard-download-icon {
    margin: 0 3px 0 5px;
    width: 14px;
}

.rcOddsTable header > .rcTitle .allup-btn.flex-center {
    min-width: 50px;
}

.rcOddsTable header.CWA > .rcTitle .allup-btn.flex-center {
    min-width: 240px;
    justify-content: flex-start;
}

.rcOddsTable header.CWA .right {
    // flex-basis: 64%;
}

.rcOddsTable header.CWA > .rcTitle .allup-btn {
    >* {
        margin-right: 5px;
        line-height: 18px;
    }

    >label{
        margin-right: 8px;
    }
}

.rcOddsTable header #lblPageName {
    margin-right: 10px;
    background-repeat: no-repeat;
    display: inline-flex;
    flex-wrap: nowrap;
    @include H2();
}
.rcOddsTable header #meetingInfoDiv{
    @include H4();
    white-space: normal;
}

.rcOddsTable header #mtgInfoHelp {
    margin-right: 10px;
}

.rcOddsTable header .right input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-left: 0px;
}

.rcOddsTable header .right > div:not(:last-child) {
    align-items: center;
    display: flex;
    @include H6();
}
.rcOddsTable header .right > div.current-odds{
    align-items:center;
    @include H4();
}

.rcOddsTable header #mtgInfoDV {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
    // min-height: 28px;
    /* justify-content: flex-start;
    align-content: center;
    align-items: center; */
    /* flex-basis: 92%; */
}

.rcOddsTable header #mtgInfoDV > div {
    display: inline-flex;
    align-items: center;
}

.rcOddsTable .CROSS_ALUP #mtgInfoDV {
    flex-basis: 100%;
}

.rcOddsTable .WPQALUP #mtgInfoDV {
    flex-basis: 106%;
}

.en .WPQ #mtgInfoDV {
    flex-basis: 115%;
}

.rcOddsTable header .allupBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include H4();
    line-height: 18px;
}

.rcOddsTable header .allupBtn .allupCheckbox {
    // margin-top: 1px;
    // vertical-align: -1px;
    margin-right: 6px;
    transform: translate(0px, -1px);
}

.rcOddsTable header .right {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px 12px;
    min-height: unset;
    margin-bottom: 0;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
}

.rcOddsTable header .mtgInfoHelp {
    height: 20px;
    width: 20px;
    margin-right: 6px;
    margin-left: 3px;
    background-image: url(../../info/Include/images/ic_help_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    cursor: pointer;
}

.rcOddsTable header .refresh-odds * {
    cursor: pointer;
    @include font11();
}

.rcOddsTable header .refresh-odds div {
    height: 20px;
    width: 20px;
    background-image: url(../../info/Include/images/ic_refresh_white.svg);
    background-size: cover;
    &.rotate {
        animation: rotate 1s linear 1;
    }
}

.rcOddsTable header #mtgInfoDV span {
    margin-right: 5px;
}

.rc-odds-table-header > div,  .rc-odds-table-header > td{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 28px;
    padding: 0 7px;
    @include H6()
}

.rc-odds-table-header .rc-odds-row .rides {
    border-right: slategrey;
}

.PWIN .rc-odds-table-header > div {
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    padding: 0 7px;
    @include font11();
}

.PWIN .rc-odds-table-header .hourseName,
.PWIN .rc-odds-table-header .jocky,
.PWIN .rc-odds-table-header .trainer {
    text-align: left;
}

.pwinheader {
    text-align: center;
    margin-bottom: 8px;
    // position: absolute;
    // width: 195px;
}

.PWIN .pwinheader {
    text-align: center;
    margin-bottom: 8px;
}

.pwinheader-m {
    position: absolute;
    width: 120px;
}
.pwinColContainer{
    display: flex;
    justify-content: space-evenly;
    >div{
        padding: 0 7px;
        flex: 1;
        text-align: center;
    }
}

.pwinCol0, .pwinCol1, .winTime {
    text-align: center;
    padding-bottom: 6px;
}

.rc-odds-table .horseName {
    text-align: left;
    // width: 155px;
    padding: 0 7px;
    word-break: break-word;
}

.PWIN .rc-odds-table .horseName {
    word-break: initial;
}

.rc-odds-table .jocky,
.rc-odds-table .trainer {
    text-align: left;
    // width: 90px;
    word-break: break-word;
}

.rc-odds-table-header {
    display: table-row;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    background: #E7E7E7;
}

.rc-odds-table {
    /* min-height: 550px; */
    // background-color: white;
    border-radius: 4px;
    display: table;
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--box-shadow);
}

.rc-odds-table .gearinfo-box > span {
    color: #122c68;
    font-weight: 500;
}

.gear-overflow {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.standby-horse-table .rc-odds-table {
    min-height: 0;
    .rc-odds-row {
        // box-shadow: var(--box-shadow);
    }
}

.meeting-button {
    height: 54px;
    background: #eaeaea;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    .slick-list{
        margin-left: 0;
    }
}

.meeting-button ul {
    list-style-type: none;
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
}

.meeting-button li {
    background-color: #6176a0;
    color: white;
    border-radius: 20px;
    width: 44px;
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

// .raceNoSlider{
//     .slick-list{
//         .slick-slide{
//             margin-right: 4px;
//         }
//     }
// }


.raceNoSliderCon {
    .RCMeetingSlider {
        // scroll-behavior: smooth;
        // scroll-snap-type: x mandatory;
        display: flex;
        overflow: auto;
    }
    .race-no-item {
        margin-right: 4px;
    }
}



.race-no-item {
    // scroll-snap-align: center;
    padding: 4px;
    position: relative;
    &.active{
        &::after{
            width: 44px ;
            height: 44px;
            content: '';
            border-radius: 50%;
            border: 2px solid #71583C;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &.active.isTurf::after{
        content: '';
        border-color: #30A100;
    }
}

.meeting-button h3 {
    background-color: #6176a0;
    color: white;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    @include font15();
    font-weight: 500;
    margin: 0px;
}

.meeting-button li.active {
    background-color: #122c68;
}
.meeting-button .meetingBtnSplitLine{
    @include width1px();
    height: 44px;
    background: #979797;
    margin: 0 8px;
}
.meeting-button .active h3 {
    background-color: #122c68;
}

.meeting-button{
    .dimmed > h3 {
        background-color: #A3AAB5;
    }
    .active.dimmed {
        &>h3{
            background-color: #60656D;
        }
    }
    .disabled > h3{
        opacity: 0.3;
    }
}


.meeting-button {
    grid-template-columns: auto auto minmax(0, 1fr) auto auto;
    // row-gap: 15px;
    display: grid;
    padding-right: 0 8px;
}

.meeting-button.slide {
    // grid-template-columns: 21% 10px 60% 21px 7%;
    padding-left: 0px;
}

.meeting-button.slide .slider-container-venue {
    // padding-right: 20px;
}

.meeting-button.slide4 {
    // grid-template-columns: 168px 10px 61% 21px 7%;
    padding-left: 0px;
}

.meeting-button.slide3 {
    // grid-template-columns: 128px 10px 66% 21px 7%;
    padding-left: 0px;
}

.meeting-button.slide2 {
    // grid-template-columns: 100px 10px 71% 21px 7%;
    padding-left: 0px;
}

.meeting-button.slide1 {
    // grid-template-columns: 48px 10px 76% 21px 7%;
    padding-left: 0px;
}

.meeting-button .separator {
    // background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    // @include width1px();
    // height: 35px;
}

.meeting-info {
    min-height: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    @include font13();
    color: #000000;
    line-height: 18px;
    margin: 0 12px;
    gap: 12px;
    // border-radius: 0 0 4px 4px;
}

.TT .meeting-info {
    display: none;
}


.JTCOMBO .meeting-info {
    justify-content: flex-end;
}

.meeting-info > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.racingBlackMessage{
    height: 32px;
    @include H5();
    display: flex;
    align-items: center;
    padding-left: 12px;
    background: #333333;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
}

.DBL .meeting-info-container,
.TBL .meeting-info-container,
.DT .meeting-info-container,
.TT .meeting-info-container,
.SIXUP .meeting-info-container {
    visibility: hidden;
}

.CROSS_ALUP .meeting-info-container,
.WPALUP .meeting-info-container,
.WPQALUP .meeting-info-container,
.FCTALUP .meeting-info-container,
.TRIALUP .meeting-info-container,
.CWAALLALUP .meeting-info-container,
.CWAALUP .meeting-info-container,
.JKC .meeting-info-container,
.TNC .meeting-info-container {
    display: none;
}

.meeting-info-no {
    margin-right: 20px;
    display: inline;
    min-width: 41px;
    white-space: nowrap;
    @include Body-Bold();
}

.meeting-info-desktop-none {
    display: none;
}

.pool-not-defined {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-left: 8px;
}

.meeting-info-content {
    display: inline;
    @include Body();
}

.odds-drop-legend {
    padding: 0 5px;
    margin: 12px 0px;
    @include font11();
}

.odds-drop-legend .header {
    padding: 8px 0px;
    @include Body-Bold();
    .OddsDropLegend-Content-lt1280 & {
        padding: 0 0px 8px 0px;
    }

}

.odds-drop-legend .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include Body();
}

.odds-drop-legend .box {
    display: inline-flex;
    margin-right: 24px;
    align-items: center;
}

.odds-drop-legend .box > div {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    display: inline;
    margin-right: 4px;
}

.jtcAddtoslip{
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 4px;
}

.box .favourite {
    background: #f03939;
}

.box .drop20 {
    background: #2aa217;
}

.box .drop50 {
    background: #993300;
}

.cwin-table-container {
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
}

.cwin-table-container .cwin-detail-scr {
    background-color: #848484;
    color: #c6c6c6;
    border-radius: 3px;
}

.cwin-table-container header {
    background-color: #6176a0;
    height: 36px;
    line-height: 36px;
    color: #fff;
    padding-left: 10px;
    border-radius: 4px 4px 0 0;
    font-weight: 500;
    @include font15();
}

.cwin-table-container .cwin-table {
    display: table;
    width: 100%;
    @include H4();
}

.cwin-table-container .cwin-table-header {
    @include H6();
}

.cwin-table .cwin-table-header .cwin-table-comp {
    padding-left: 20px;
}

.cwin-table-header .cwin-table-detail {
    align-items: center;
    line-height: 20px;
}

.cwin-table-header .cwin-detail-switch {
    margin: 0 8px;
}

.cwin-table-header .cwin-table-odds {
    text-align: center;
}

.cwin-table .cwin-table-comp {
    width: 25%;
}

.cwin-table .cwin-table-odds {
    width: 15%;
    align-items: center;
}

.cwin-table .cwin-table-odds .cwin-odds {
    margin-left: 8px;
    height: 24px;
    width: 28px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    color: #122c68;
}

.cwin-table .cwin-table-detail {
    width: 60%;
}

.cwin-table-detail .cwin-table-detail-item {
    /* margin: 5px 0; */
    padding-left: 5px;
    line-height: 18px;
}

.cwin-table-detail .cwin-table-detail-rl {
    flex-wrap: wrap;
    align-items: center;
}

.cwin-table-detail span,
.cwin-table-comp span {
    padding: 0 12px;
    line-height: 24px;
    height: 24px;
}

.cwin-table-comp > div,
.cwin-table-detail-item > div {
    line-height: 24px;
}

.cwin-table .cwin-table-odds .favourite {
    background-color: #f03939;
    color: #fff;
}

.cwin-table .cwin-table-odds .drop50 {
    background-color: #2aa217;
    color: #fff;
}

.cwin-table .cwin-table-odds .drop20 {
    background-color: #993300;
    color: #fff;
}

.cwin-table .sec {
    background-color: #f4f4f4;
}

.cwin-table .table-cell {
    padding: 5px;
}

.cwin-table .table-cell > div {
    width: 100%;
}

.race-result-table-container .race-result-table {
    display: table;
    width: 100%;
    background-color: #f4f4f4;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    // overflow: hidden;

}

.race-result-table-container.mobile .race-result-table {
    // border-radius: 0;
    box-shadow: none;
    background-color: unset;
    >div {
        &:nth-child(odd):not(:first-child) {
            background-color: #f4f4f4;
        }
        &:nth-child(2){
            overflow: hidden;
            &>div:first-child {
                border-top-left-radius: 4px;
            }
            &>div:last-child {
                border-top-right-radius: 4px;
            }
        }
        &:last-child{
            box-shadow: var(--box-shadow);
            overflow: hidden;
            &>div:first-child {
                border-bottom-left-radius: 4px;
            }
            &>div:last-child {
                border-bottom-right-radius: 4px;
            }
        }
    }

}

.race-result-table > .sec {
    background-color: #fff;
}

.race-result-table .race-result-table-row-m,
.race-result-table .race-result-table-header-m {
    display: none;
}

.race-result-table .race-result-table-row > div:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 10%;
    display: block;
    height: 80%;
    @include width1px();
    background-color: #ccc;
}

.race-result-table-row .jocky a,
.race-result-table-row .trainer a {
    color: #122c68;
    cursor: pointer;
}

.RESULTS .result-msg {
    padding: 10px 8px;
    line-height: 22px;
    white-space: 'pre-wrap';
}

.RESULTS .masonry-col > div:first-child,
.RESULTS .masonry-col > div:last-child {
    width: 50% !important;
}

.dividend-table-container > header {
    height: 36px;
    color: #fff;
    @include font15();
    font-weight: bold;
    line-height: 36px;
    padding-left: 10px;
    background-color: #122c68;
    border-radius: 4px 4px 0 0;
}

.dividend-table-container .dividend-table {
    width: 100%;
    display: table;
    background-color: #fff;
}

.dividend-table .sec {
    background-color: #f4f4f4;
}

.dividend-table > div > div {
    padding: 3px;
    line-height: 18px;
    position: relative;
}

.dividend-table > div > div .switch-btn-icon {
    align-self: center;
    min-width: 21px;
    margin: 0 8px;
}

.dividend-table .divi-cwin-item span {
    margin-right: 8px;
}

.dividend-table .divi-table-iwn-ctrl {
    width: 50%;
    justify-content: space-between;
}

.dividend-table .dividend-table-header > div:last-child {
    /* width: 280px; */
}

.dividend-table > div > div:first-child {
    padding-left: 10px;
}

.dividend-table > div > div:last-child {
    padding-right: 10px;
}

.dividend-table .dividend-table-pool {
    display: flex;
    padding: 3px 3px 3px 10px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
}

.dividend-table .table-cell-r {
    justify-content: space-between;
    align-items: center;
    line-height: 32px;
}

.dividend-table .table-cell-r > div:first-child {
    max-width: 70%;
    line-height: 18px;
}

.race-result-table > div > div {
    position: relative;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 28px;
}

.race-result-table .race-result-table-header div {
    height: 32px;
}

.AddToSlip {
    width: 100px;
    height: 28px;
    background: #FECF13;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #333333;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    // margin-right: 12px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    &.disabled{
        background: #CCCCCC !important;
        pointer-events: none;
        color: #FFFFFF;
        opacity: 0.5;
        cursor: initial;
    }
    &:active {
        background: #E4B910;
    }
    @media (any-hover: hover) {
        &:hover{
            background: #E4B910 !important;
        }
        &.disabled:hover{
            background: #CCCCCC !important;
        }
    }
}

.FB .AddToSlip {
    margin-right: 12px;
}

// .ch .AddToSlip {
//     width: 120px;
// }

.investment-cal {
    display: grid;
    grid-template-columns: 1fr 1fr minmax(25%, max-content) minmax(25%, max-content);
    background-color: white;
    align-items: center;
    height: 44px;
    border-top: 1px solid #dfdfdf;

    // border-radius: 0 0 4px 4px; 此處只能通過編程的方式來改，因爲有些地方需要，有些地方不需要
    box-shadow: var(--box-shadow);
    > div > div{
        &:first-child{
            @include Body();
        }
        &:last-child{
            @include H2();
        }
    }
}

.border-radius-up {
    border-radius: 4px 4px 0 0;
}

.border-radius-down {
    border-radius: 0 0 4px 4px;
}

.border-radius-all {
    border-radius: 4px;
}

.investment-cal-lbl {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 12px;
    height: 100%;
    @include H4();
}

.investment-cal-arow {
    display: none;
}

.investment-cal-betno {
    display: flex;
}

.investment-cal-betno > div {
    padding: 0px 0px 0px 12px;
}

.investment-cal-unitbet,
.investment-cal-bettotal {
    display: flex;
    align-items: center;
}

.investment-cal-bettotal {
    justify-self: flex-end;
}

.investment-cal-unitbet > div,
.investment-cal-bettotal > div {
    padding: 0px 12px 0px 0px;
}

.investment-cal-unitbet-input {
    position: relative;
    width: 140px;
    height: 32px;
    @include font15();
    font-weight: bold;
}
.investment-cal-unitbet-input div {
    @include font11();
    text-align: right;
    font-weight: normal;
}

.investment-cal-unitbet-input span {
    position: absolute;
    left: 5px;
    top: 7px;
}
.investment-cal-unitbet-input .flexi {
    top: 24px;
}

.investment-cal-unitbet-input input {
    width: 100%;
    height: 100%;
    text-align: right;
    border: 1px solid #757575;

    border-radius: 1px;
    padding-right: 8px;
    &:focus {
        background-color: #FFFBE6;
    }
}

.investment-cal-unitbet-input input:focus {
    border: 1px solid #000000;

}

.investment-cal .betTotalInput:disabled {
    border: 0;
    background-color: #ffffff;
}

.invCollapse a button {
    background: #6176a0;
    border-radius: 4px;
    border: unset;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 14px;
}

.invCollapse a .expend div {
    transform: rotateZ(-135deg);
    transition: all 0.2s ease-in-out 0s;
    display: flex;
    top: 2px;
    left: 1px;
    position: relative;
}

.invCollapse a .collaspse div {
    transform: rotateZ(45deg);
    transition: all 0.2s ease-in-out 0s;
    display: flex;
    top: -1px;
    left: 0px;
}

.invCollapse a label {
    @include font13();
    color: #333333;
    margin-left: 14px;
    cursor: pointer;
    height: 22px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.unit-bet-label {
    @include font13();
    color: #333333;
    line-height: 18px;
    font-weight: 400;
    margin-right: 8px;
}

.unit-bet-input {
    width: 120px;
    height: 28px;
    border: 1px solid #757575;

    border-radius: 2px;
    padding-left: 5px;
}

.collapseInvestmentCal {
    transition: all 0.2s ease-in-out 0s;
    margin-bottom: 0px;
    border-radius: 0px 0px 4px 4px;
}

.collapseInvestmentCal .card-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    background: #f4f4f4;
    border-radius: 0px 0px 4px 4px;
    border: unset;
    margin: 0;
    padding: 0;
}

.collapseInvestmentCal .card-body div {
    flex-basis: 30%;
}

.collapseInvestmentCal .card-body div:last-child {
    flex-basis: 38%;
}

.collapseInvestmentCal .card-body div div {
    display: inline;
    text-align: left;
    @include font13();
}

.invCollapse {
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.card-cell {
    height: 47px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.collapseInvestmentCal .card-body .card-cell:not(:last-child):after {
    background-size: 10px 20px;
    display: inline-block;
    @include width1px();
    height: 23px;
    vertical-align: middle;
    content: '';
    border-left: 1px #c9c8c8 solid;

}

.rc-odds-row {
    display: table-row;
    color: #333333;
    height: 44px;
    border-radius: 4px;
}

.rcOddsTable .rc-odds-row a,
.rcOddsTable .rc-odds-m a,
.rcOddsTable .horseName a {
    cursor: pointer;
    color: #122c68;
    font-weight: 500;
}

.rc-odds-row.sec > div,
.rc-odds-row.sec > td,
.rc-odds-row-m.sec {
    background: #f4f4f4;
}

.rc-odds-row.full > td {
    border-top: 1px #dee2e6 solid;

    white-space: break-spaces;
    // text-align: left;
}

.rcOddsTable section {
    width: 100%;
}

.rc-odds-row > div, .rc-odds-row > td {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 30px;
    padding: 0 7px;
    @include font13();
    background-color: #fff;
}

.rc-odds-row:nth-child(-n+2)>td:first-child {
    border-top-left-radius: 4px;
}

.rc-odds-row:nth-child(-n+2)>td:last-child {
    border-top-right-radius: 4px;
}

.rc-odds-row-m:last-child>td:first-child {
    border-bottom-left-radius: 4px;
    width: 28px;
    // padding-left: 10px;
}

.rc-odds-row-m:last-child>td:last-child {
    border-bottom-right-radius: 4px;
}

.rc-odds-row-m:last-child>td:nth-last-of-type(2) {
    border-bottom-right-radius: 4px;
}

.rc-odds-row > td.rc-checkbox.rc-odds {
    white-space: nowrap;
    @include H4();
}

.rc-odds-scratch {
    position: relative;
}

.rc-odds-scratch > div {
    // padding-top: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rc-odds-row.betSlip {
    height: 48px;
    // border-top: 0.5px solid #dbdbdb;
}

.rc-odds-row.betSlip > div {
    display: flex;
    position: absolute;
    float: right;
    justify-content: flex-end;
    width: 100%;
    height: 46px;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    border-top: 1px solid #dbdbdb;

}

.rc-odds-row.betSlip .AddToSlip {
    display: flex;
    justify-content: center;
    width: 87px;
    height: 32px;
    border-radius: 30px;
}

.rc-odds-row-compact.betSlip {
    height: 48px;
}

.rc-odds-row-compact.betSlip > div {
    display: flex;
    position: absolute;
    float: right;
    justify-content: flex-end;
    width: 100%;
    height: 46px;
    border-top: 0.5px solid #dbdbdb;

    background-color: #ffffff;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

.rc-odds-row-compact.betSlip .AddToSlip {
    display: flex;
    justify-content: center;
    width: 87px;
    height: 32px;
    border-radius: 30px;
}

.multi-leg-footer-betSlip {
    height: 48px;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0 ;
    margin-top: 12px;
    width: 100%;
    box-shadow: var(--box-shadow);
}

.multi-leg-footer-betSlip > div {
    display: flex;
    /* position: absolute;
    float: right; */
    justify-content: flex-end;
    /* width: 828px; */
    height: 100%;
    align-items: center;
    /* align-content: center; */
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
}

.multi-leg-footer-betSlip .AddToSlip {
    display: flex;
    justify-content: center;
    height: 28px;
    border-radius: 30px;
}

.rc-checkbox.rc-odds {
    // min-width: 86px;
}

.PWIN .rc-checkbox.rc-odds {
    // min-width: 65px;
}

.PWIN .pwin-m {
    transform: translate(0, 4px);
}

.PWIN .pwinCol {
    min-width: 52px;
    vertical-align: middle;
}

.rc-checkbox .checkbox-container {
    // float: left;
    // margin: 6px 2px 0px 10px;
    vertical-align: middle;
    margin-right: 6px;
}

.rc-odds-table-m .rc-checkbox .checkbox-container {
    margin-right: 0;
}

.rcPoolInvTop {
    margin-top: 12px;
}

.rcPoolInvTop .header {
    padding: 4px 4px 0px 4px;
    font-weight: bold;
}

.rcPoolInvTitle {
    height: 36px;
    background-color: #122c68;
    color: white;
    border-radius: 4px 4px 0 0;
    padding: 8px 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include H2();
}

.rc-inv-table {
    background-color: white;
    display: table;
    // border-bottom-left-radius: 4px;
    // border-bottom-right-radius: 4px;
    width: 100%;
    /* border-right: 0.5px solid #dbdbdb; */
    overflow: hidden;
    @include H4();
}
.rc-inv-table-container{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: var(--box-shadow);
}

.rc-inv-table-left {
    border-right: 1px solid #dbdbdb;

}

.rc-inv-table-row {
    display: table-row;
    background-color: white;
    box-shadow: var(--box-shadow);
}

.rc-inv-table-row > td {
    display: table-cell;
    width: 50%;
    vertical-align: top;
    padding: 0;
}

.rc-inv-row {
    display: table-row;
}

.rc-inv-row td {
    display: table-cell;
    vertical-align: middle;
    border-bottom: 0.5px solid #dbdbdb;

    height: 30px;
    padding: 0 0px 0px 13px;
    width: 50%;
    color: #333333;
}
.rc-inv-row:last-child td{
    border-bottom: none;
}

.rc-inv-row td:nth-child(2) {
    text-align: right;
    padding: 0 13px 0px 0px;
}

.rc-inv-row-total td {
    background-color: #dbe6ef;
    color: #122c68;
}

.merge-comingle-remarks {
    padding: 4px 4px 0px 4px;
}

.rc-comingle-remarks,
.rc-mergepool-remarks {
    padding: 4px 4px 0px 4px;
}

.rc-comingle-remarks:last-child{
    padding: 4px 4px 4px 4px;
}

.FF .rc-mergepool-remarks,
.QTT .rc-mergepool-remarks {
    display: none;
}

.rc-colour {
    border: none;
    width: 38px;
    height: 29px;
    background-color: unset;
    background-size: cover;
}

.ColourImageForNoCopy {
    min-height: 30px;
    min-width: 30px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: block !important;
}

.rc-colour > img {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
}

.rc-note {
    width: 24px;
    height: 24px;
}

.rc-note.add {
    background: url(../../info/Include/images/notes_new.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}

.rc-note.update {
    background: url(../../info/Include/images/notes_view.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}

.rc-note.fav {
    background: url(../../info/Include/images/notes_fav.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}

.rc-odds {
    // min-width: 80px;
}

.rc-odds .favourite a,
.rc-odds-m .favourite a,
.rc-odds .favourite span,
.rc-odds-m .favourite span {
    background: #f03939;
    --font-color: #fff;
    color: var(--font-color);
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: nowrap;
}

.rc-odds  a,
.rc-odds-m  a,
.rc-odds  span,
.rc-odds-m  span {
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: nowrap;
}
.rc-odds-text{
    line-height: 30px;
}

.full {
    .rc-odds  a,
    .rc-odds-m  a,
    .rc-odds  span,
    .rc-odds-m  span {
        height: 0;
    }
}


.rc-odds .od-20 a,
.rc-odds-m .od-20 a,
.rc-odds .od-20 span,
.rc-odds-m .od-20 span {
    background: #2aa217;
    --font-color: #fff;
    color: var(--font-color);
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

.rc-odds .od-50 a,
.rc-odds-m .od-50 a,
.rc-odds .od-50 span,
.rc-odds-m .od-50 span {
    background: #993300;
    --font-color: #fff;
    color: var(--font-color);
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

.rc-odds a,
.rc-odds-m a {
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: nowrap;
}

.rc-inv-row.rc-inv-row-total {
    font-weight: bold;
}

.poolinv-total-remarks {
    padding: 12px 4px 0px ;
}

.rc-inv-row.sec {
    background: #f6f6f6;
}

.rc-inv-row *:not(:last-child):after {
    background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    background-size: 10px 20px;
    display: inline;
    @include width1px();
    height: 18px;
    vertical-align: middle;
    content: '';
    float: right;
    opacity: 0.4;
}

.card-cell .amount {
    @include font13();
    color: #122c68;
    line-height: 18px;
    font-weight: 500;
}

.live-racing-icon {
    background-image: url(../../info/Include/images/icon-live-racing-m.svg);
    height: 25px;
    width: 45px;
    margin-left: 10px;
    background-repeat: no-repeat;
    filter: invert(45%) sepia(13%) saturate(1221%) hue-rotate(182deg) brightness(97%) contrast(88%);
}

.TRI .current-odds,
.FF .current-odds {
    display: none;
}

.current-odds #dropdown-currentodds {
    width: 20px;
    height: 20px;
    background-image: url(../../info/Include/images/Dropdown_menu_btn.svg);
    background-repeat: no-repeat;
    background-color: #122c68;
    background-size: cover;
    border: unset;
    margin: 0px;
    padding: 0px;
}

.current-odds .dropdown-toggle:after {
    display: none;
}

.current-odds .dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none !important;
}

.current-odds .dropdown-menu {
    border-radius: 10px;
    overflow: hidden;
}

.current-odds .dropdown-item {
    width: 260px;
    height: 31px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    &.active{
        background-color: #fff;
        color: #173E96;
    }
}

.show #dropdown-currentodds {
    transform: rotateZ(180deg);
}

.current-odds label {
    margin-right: 4px;
}

.rc-checkbox.rc-odds div {
    line-height: 28px;
    display: inline;
}

.rc-inv-table-row:last-child > div:first-child,
.rc-odds-row:last-child > td:first-child {
    border-bottom-left-radius: 4px;
}

.rc-inv-table-row:last-child > div:last-child,
.rc-odds-row:last-child > td:last-child {
    border-bottom-right-radius: 4px;
}

.rc-odds-table input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 18px;
    height: 18px;
    border: 1px #b3b3b3 solid;

    outline: none;
    border-radius: 2px;
}

.rc-jtc-search-box {
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
}

.addSlip {
    float: right;
    padding: 6px 0px;
}

.hiddenAddSlip {
    display: none;
}

.rc-jtc-search-item {
    clear: both;
    border-top: 1px solid #d6d6d6;

    padding: 8px 12px 0px 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}

.rc-jtc-search-item a {
    color: #333333;
}

.rc-jtc-search-item a:hover {
    color: #173e96;
}

.rc-jtc-search-item ul {
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
    padding: 4px 0 12px 0;
    align-items: flex-end;
    flex-grow: 1;
    margin-bottom: unset;
}

.rc-jtc-search-item ul li {
    display: inline-block;
    position: relative;
    // width: 29%;
    // flex: 1;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 12px;
    gap: 8px;
}

.rc-jtc-search-item ul li.rc-jtc-btn {
    // width: auto;
}

.rc-jtc-dropdown {
    border: 1px solid #000000;

    background-color: #ffffff;
    width: 140px;
    height: 28px;
    line-height: 20px;
    vertical-align: middle;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0;
    // margin: 8px 0px;
}

.rc-jtc-dropdown > div:nth-child(1) {
    margin: auto 8px;
    display: inline-block;
    @include textEllipsis();
}

.rc-jtc-dropdown > div:nth-child(2) {
    margin: 4px 8px 4px 0px;
    display: inline-block;
}

.rc-jtc-dropdown-menu {
    position: absolute;
    transform: translate(0px, 40px);
    width: 200px;
    height: 300px;
    z-index: 9999;
    right: 0;
}

.rc-jtc-dropdown-header {
    text-align: center;
    background-color: #122c68;
    color: #ffffff !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px;
    &:hover{
        background-color: #122c68;
        color: #ffffff !important;
    }
}

.rc-jtc-dropdown-body {
    background-color: #ffffff;
    color: #333333 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rc-jtc-dropdown-item {
    padding: 8px;
    cursor: pointer;
}

.rc-jtc-btn {
    // float: right;
    // margin-top: 4px;
    margin-top: auto;
    // margin-bottom: 8px;
    padding-bottom: 10px;
    padding-left: 36px;
    flex: 0 0 auto;
}

.rcOddsTable .rc-tt-dropdown {
    border: 1px solid #000000;

    background-color: #ffffff;
    width: 298px;
    height: 28px;
    line-height: 20px;
    vertical-align: middle;
    text-align: left;
    // margin: 8px 12px;
    // padding-left: 8px;
}

.rc-tt-dropdown >div span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 30px);
}

.rc-tt-dropdown-menu {
    position: absolute;
    transform: translate(0px, 10px);
    width: 300px;
    height: 300px;
    z-index: 9999;
    right: 0;
}

.rc-tt-dropdown-header {
    text-align: center;
    background-color: #122c68;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px;
}

.rc-tt-dropdown-body {
    background-color: #ffffff;
    color: #333333;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rc-tt-dropdown-item {
    padding: 8px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TT .radio-row {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 12px;
    box-shadow: var(--box-shadow);
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #dfdfdf;
}

.TT .dd-arrow-down {
    margin-top: -16px;
}

.TT .radio-row-m {
    display: none;
}

.TT .radio-row .separator {
    background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    display: inline-block;
    margin: 0px;
    padding: 0px;
    @include width1px();
    height: 28px;
    // margin: 6px 10px 6px 10px;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    width: auto;
    flex-wrap: nowrap;
    flex-direction: row;
}

@media screen and (max-width: 1280px) {
    .TT .radio-row {
        word-break: keep-all;
        grid-row-gap: 6px;
        .separator {
            display: none;
        }
        grid-template-columns: auto auto auto;
    }
}

.dropdown-menu.show {
    inset: -2px 11px auto auto !important;
}

.current-odds .dropdown-menu.show {
    inset: 12px -11px auto auto !important;
}

.rc-odds-table .race-gear-legend {
    color: #333;
    display: block;
    text-align: left;
    width: 600px;
    right: -60px;
    bottom: 40px;
    background-color: #f4f4f4;
    padding: 0;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 3px 8px #333;
    z-index: 999;
}

.rc-odds-table .race-gear-legend div {
    display: block;
    text-align: left;
    height: auto;
    padding: 0;
    line-height: 14px
}

.rc-odds-table .race-gear-legend h5 {
    @include font15();
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-weight: bold;
    background-color: #122c68;
    margin: 0;
    color: #fff;
    border-radius: 10px 10px 0 0;
}

.rc-odds-table .race-gear-legend .gear-info-list {
    display: flex;
    flex-wrap: wrap;
}

.rc-odds-table .race-gear-legend .gear-info-list > div {
    width: 300px;
}

.rc-odds-table .race-gear-legend .gear-info-left {
    border-right: solid 1px #ccc;

}

.rc-odds-table .race-gear-legend .gear-info-item {
    padding: 0 15px;
    display: flex;
    line-height: 30px;
    height: 30px;
    border-top: 1px solid #ccc;

    align-items: center;
}

.rc-odds-table .race-gear-legend .gear-info-list :nth-last-of-type(1) {
    border-radius: 0 0 10px 0;
}

.rc-odds-table .race-gear-legend .gear-info-list :nth-last-of-type(2) {
    border-radius: 0 0 0 10px;
}

.rc-odds-table .race-gear-legend .active-gear {
    background: #dbe8f3;
}

.rc-odds-table .race-gear-legend .gear-info-item .gearinfo-item-name {
    width: 60px;
    color: #003c84;
    font-weight: bold;
}

.rc-odds-table .race-gear-legend .gear-info-item .separator {
    @include width1px();
    height: 20px;
    background: #ccc;
    margin-right: 10px;
}

.rc-odds-table .rc-odd-draw {
    width: 42px;
}

.rc-odds-table .rc-odd-wt {
    width: 44px;
}

.horse-racing-race-card .rc-odds-table-header .tp {
    min-width: 30px;
    max-width: 30px;
    width: 30px;
}

.horse-racing-race-card .rc-odds-table-header .no {
    min-width: 30px;
    max-width: 30px;
}

.horse-racing-race-card .rc-odds-table-header .colour,
.horse-racing-race-card .rc-colour {
    min-width: 56px;
    max-width: 56px;
    padding: 0;
}

.rc-odds-table-header .colour {
    white-space: nowrap;
}

.rc-odds-table-header .RCNOTE {
    min-width: 40px;
    max-width: 40px;
}

.horse-racing-race-card .rc-odds-table-header .rtg,
.horse-racing-race-card .rc-rtg {
    min-width: 36px;
}

.horse-racing-race-card .rc-odds-table-header .bodyWT {
    min-width: 40px;
    max-width: 40px;
}

.horse-racing-race-card .rc-odds-table-header .draw,
.horse-racing-race-card .rc-odd-draw {
    min-width: 36px;
    width: 36px;
}

.horse-racing-race-card .rc-odds-table-header .jocky {
    min-width: 70px;
    max-width: 70px;
}

.horse-racing-race-card .rc-odds-table-header .trainer {
    min-width: 70px;
    max-width: 70px;
}

.horse-racing-race-card .rc-odds-table .horseName {
    min-width: 115px;
    max-width: 115px;
    padding: 0 5px;
    width: auto;
    word-wrap: break-word;
}

.horse-racing-race-card .rc-odds-table-header .last6 {
    min-width: 130px;
    max-width: 130px;
}

.horse-racing-race-card .rc-odds-row > td {
    padding: 0 5px;
}

.rc-page-footer-legend {
    padding: 15px 5px 0px;
}

.rc-page-footer-title {
    font-weight: bold;
    margin-bottom: 3px;
}

.rc-page-footer-content > .left {
    margin-right: 8px;
}

.rc-page-footer-content > .right {
    flex-wrap: wrap;
}

.rc-page-footer-content > .right > div {
    padding-right: 25px;
    height: 20px;
}

.rc-page-footer-content > div > img {
    margin-top: -5px;
}

.symbol-plus, .symbol-star {
    width: 8px;
}

.symbol-plus-20, .symbol-star-20 {
    width: 20px;
}

.rc-odds-table .rc-odds-table-tp {
    display: flex;
    width: 40px;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.rc-odds-table-tp .tp-tc-and-pri {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
    padding: 0;
    width: 24px;
}

.rc-odds-table-tp .tp-tc-and-pri > div {
    width: 50%;
}

.rc-odds-table-tp .tp-pref {
    height: 28px;
    width: 40px;
    margin: 0 6px;
}

.rc-odds-table-tp .tp-djaC {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rc-odds-table-tp .number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    background: #DBE6EF;
    display: inline-grid;
    place-content: center;
    border-radius: 50%;
}

.standby-horse-table header {
    height: 30px;
    font-weight: bold;
    line-height: 30px;
    padding-left: 15px;
    @include font13();
    margin: 8px 0;
    border-radius: 4px;
}

.rc-odds-table-m {
    display: none;
}

.invCollapse-m,
.cal-input {
    display: none;
}

.allupformula {
    display: none;
}

.CROSS_ALUP .meeting-info-allup .allupformula,
.CWAALLALUP .meeting-info-allup .allupformula,
.CWAALUP .meeting-info-allup .allupformula,
.WPALUP .meeting-info-allup .allupformula,
.WPQALUP .meeting-info-allup .allupformula,
.FCTALUP .meeting-info-allup .allupformula,
.TRIALUP .meeting-info-allup .allupformula {
    display: block;
}

.CROSS_ALUP .meeting-info-allup-m,
.CWAALLALUP .meeting-info-allup-m,
.JTCOMBO .meeting-info-allup-m,
.CWAALUP .meeting-info-allup-m,
.WPALUP .meeting-info-allup-m,
.WPQALUP .meeting-info-allup-m,
.FCTALUP .meeting-info-allup-m,
.TRIALUP .meeting-info-allup-m {
    display: none;
}

.JTCOMBO .meeting-info-allup,
.CROSS_ALUP .meeting-info-allup,
.CWAALLALUP .meeting-info-allup,
.WPALUP .meeting-info-allup,
.WPQALUP .meeting-info-allup,
.FCTALUP .meeting-info-allup,
.TRIALUP .meeting-info-allup {
    position: relative;
    margin-right: 36px;
}

.rc-menu-formula-body {
    background-color: #ffffff;
    color: #333333;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 25px 40px;
}

.rc-menu-formula {
    display: inline-block;
    text-align: center;
    width: 48px;
}

.rc-menu-formula-item {
    padding: 4px 0px;
    border: 1px solid #122c68;

    border-radius: 4px;
}

.rc-menu-formula a {
    color: #333333;
}

.rc-menu-formula-item:hover {
    background-color: #122c68;
}

.rc-menu-formula a:hover {
    color: #ffffff;
}

.rc-menu-formula-1 {
    margin-left: 24px;
}

.rc-menu-formula-margin-top {
    margin-top: 12px;
}

.dropdown-allup-formulas {
    width: 140px;
    border: 1px solid #333333;

    height: 28px;
    margin-left: 12px;
}

.dropdown-allup-formulas.jkcAllup {
    margin-left: 0;
    &> .btn-Formulas{
        align-items: baseline;
    }
    #jkcAllup{
        padding: 0;
        &> div:nth-child(1) {
            margin: auto 0px auto 8px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .dropdown-menu.show{
        // height: 300px;
        width: 200px;
        color: #173e96;
        padding: 0;
        border-radius: 10px;
        inset: -2px -2px auto auto !important;
        > div {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .dropdown-item{
            min-height: 30px;
            padding-top: 8px;
            padding-bottom: 6px;
        }
        .dropdown-item:not(:first-child) {
            border-top: 1px #d2d2d2 solid;
        }
        .dropdown-item:before{
            border-color: transparent transparent #122c68;
            right: 28px;
        }
    }
}
.dropdown-allup-formulas.rc-tt-dropdown{
    #jkcAllup{
        padding: 0 0 0 8px;
        width: 100%;
    }
    .btn-Formulas{
        align-items: center;
    }
    span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 34px);
        text-align: left;
    }
    .dropdown-menu.show{
        width: 300px;
        height: auto;
        inset: 10px 0 auto auto !important;
        .dropdown-item{
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

// .ch .JTCOMBO .meeting-info-allup {
//     margin-right: 43px;
// }

.dropdown-allup-formulas > .btn-Formulas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset;
    border: 0;
}

.dropdown-allup-formulas .dd-arrow-down {
    margin-top: -4px;
}

.allupformula .dropdown-item:before {
    border-color: transparent transparent #003c84;
    right: 32px;
}

.allupformula .dropdown-item:first-child {
    background-color: #003c84;
    color: white;
    width: 100%;
    @include font15();
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.allupformula .dropdown-item:not(:first-child) {
    display: grid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    background: white;
    justify-items: center;
    grid-template-columns: 35px 35px;
    column-gap: 10px;
    row-gap: 15px;
    padding: 20px 0px;
    height: auto;
}

.allupformula .dropdown-item:not(:first-child) a {
    width: 48px;
    text-align: center;
    height: 28px;
    border-radius: 4px;
    color: #003c84;
    padding-top: 5px;
    border: 1px solid #003c84;

}

.allupformula .dropdown-item:not(:first-child) a.active {
    background-color: #003c84;
    color: white;
}

.allupformula .dropdown-menu {
    background-color: #003c84;
    padding-bottom: 0px;
    inset: 11px -23px auto auto !important;
    height: auto;
    border-radius: 10px;
    width: 200px;
    padding: 0px;
}

.rc-jtc-allup-dropdown {
    display: none;
}

.JTCOMBO .meeting-button .separator.live-icon {
    display: none;
}

.JTCOMBO .rc-jtc-allup-dropdown {
    display: none;
}

.JTCOMBO .meeting-button {
    justify-content: space-between;
    display: flex;
}

.JTCOMBO .meeting-info .meeting-info-container {
    display: none;
}

.live-racing-icon {
    margin-right: 25px;
}

.WPQ .odds-content-main {
    display: grid;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    justify-items: stretch;
    align-content: center;
    grid-template-columns: calc(100% - 342px) 342px;
    grid-template-rows: auto;
    margin-top: 12px;
}

.rc-odds-table-compact-race-header {
    display: flex;
    width: 100%;
    height: 36px;
    background-color: #6176a0;
    color: #ffffff;
    border-radius: 4px 4px 0 0;
    align-items: center;
}

.rc-odds-table-compact-race-header > div {
    margin-left: 12px;
    width: auto;
}

.rc-odds-table-compact-race-header .separator {
    background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    display: inline-block;
    margin: 0px;
    padding: 0px;
    @include width1px();
    height: 24px;
    margin: 6px 0px 6px 12px;
}

.rc-odds-table-compact-race-subheader {
    display: block;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    color: #333333;
    padding: 8px 12px;
}

.rc-odds-table-compact {
    // background-color: white;
    display: table;
    width: 100%;
    border-collapse: collapse;
    .checkbox-container{
        margin: 0 6px;
    }
}

.rc-odds-table-compact > tr:nth-child(even),
.QTT .rc-odds-table-compact > tr:nth-child(odd) {
    background-color: #ffffff;
}

.rc-odds-table-compact > tr:nth-child(odd),
.QTT .rc-odds-table-compact > tr:nth-child(even) {
    background-color: #f4f4f4;
}

.rc-odds-table-compact.CWA {
    margin-bottom: 12px;
    box-shadow: var(--box-shadow);
}

.rc-odds-table-compact .isTop {
    border-radius: 4px;
}

.rc-odds-table-compact .rc-odds-table-header-m {
    display: table-row;
    background: #f4f4f4;
    @include font11();
}

.rc-odds-table-compact .rc-odds-table-header-m td {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    min-height: 30px;
    padding: 4px 0px;
    @include font11();
}

.WPQ .rc-odds-table-header-m > td,
.DBL .rc-odds-table-header-m > td,
.TBL .rc-odds-table-header-m > td,
.DT .rc-odds-table-header-m > td,
.TT .rc-odds-table-header-m > td,
.SIXUP .rc-odds-table-header-m > td {
    background-color: #f4f4f4;
}

.rc-odds-table-compact .rc-odds-table-header-m .no,
.rc-odds-table-compact .rc-odds-row-m .no,
.rc-odds-table-compact .rc-odds-table-header-m .banker,
.rc-odds-table-compact .rc-odds-table-header-m .leg,
.rc-odds-table-compact .rc-odds-row-m .rc-checkbox {
    text-align: center;
    // width: 9%;
}

.rc-odds-table-compact .rc-odds-row-m .no,
.rc-odds-table-compact .rc-odds-table-header-m .no{
    width: 28px;
    padding-left: 8px;
    @media only screen and (min-width: 1680px){
        width: 36px;
    }
}


.rc-odds-table-compact .rc-odds-table-header-m .horseName {
    margin: 8px 4px;
    padding-left: 4px;
}

.rc-odds-table-compact .horseName.simulcast {
    margin: 8px 4px;
}

.rc-odds-table-compact .horseName {
    padding: 8px 4px;
}

.rc-odds-table-compact .rc-odds-table-header-m .win,
.rc-odds-table-compact .rc-odds-table-header-m .place {
    width: 12%;
    text-align: center;
}
.rc-odds-table-compact .rc-odds-table-header-m .win,
.rc-odds-table-compact .rc-odds-row-m .rc-odds-m{
    width: calc(12% + 8px);
    padding-right: 8px;
}

.rc-odds-table-compact .rc-odds-table-header-m .RCNOTE {
    text-align: center;
}

.rc-odds-table-compact .rc-odds-row-m .RCNOTE {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rc-odds-table-compact .rc-odds-row-m {
    display: table-row;
}

.rc-odds-table-compact .rc-odds-row-m > td {
    display: table-cell;
    vertical-align: middle;
    height: 32px;
    word-break: break-word;
}

.rc-odds-table-compact .rc-odds-row-m > td.no {
    word-break: keep-all;
    width: 30px; //PRJ1027-7367
}

.rc-odds-table-compact .favourite a,
.rc-odds-table-compact .od-20 a,
.rc-odds-table-compact .od-50 a {
    height: 22px;
}

.rc-odds-table-compact .table-arrow-m {
    display: none;
}

.JTCOMBO .meeting-button .separator.live-icon {
    display: none;
}

.WPQ .radio-button-set {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0;
    align-items: center;
    justify-items: center;
    overflow: hidden;
}

// .TRI .rc-odds-table-compact .rc-odds-table-header-m {
//     >:first-child{
//         border-top-left-radius: 4px;
//     }
//     >.pwinCol-m{
//         border-top-right-radius: 4px;
//     }
// }
.WPQ .mobile-tab-set,
.FCT .mobile-tab-set,
.TCE .mobile-tab-set,
.QTT .mobile-tab-set {
    display: none;
}

.AddSlipBtn-Content {
    display: flex;
    background-color: white;
    height: 48px;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px 4px 0 0 ;
    box-shadow: var(--box-shadow);
}

.OddsDropLegend-Content-ge1280 {
    display: block;
}

.OddsDropLegend-Content-lt1280 {
    display: none;
}

.FCT .radio-button-set > div,
.TCE .radio-button-set > div {
    /* display: inline-block; */
}

.FCT .radio-button-set,
.TCE .radio-button-set,
.QTT .radio-button-set {
    display: grid;
    grid-template-columns: repeat(6, 16.6%);
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.FCT .radio-button-set > div,
.TCE .radio-button-set > div,
.QTT .radio-button-set > div {
    padding: 4px;
}

.FCT .radio-button-set .method-title,
.TCE .radio-button-set .method-title,
.QTT .radio-button-set .method-title {
    grid-column: 1 / 7;
    line-height: 18px;
    font-weight: 500;
    padding-left: 8px;
}

.radio-button-set-item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    padding: 0 4px;

    .radio-checkmark {
        background-color: transparent; // 重置样式
    }

    .radio-container input:checked ~ .radio-checkmark {
        background-color: transparent; // 重置样式
    }

    .HR &:not(.radio-button-set-item-checked, .radio-button-set-item-disabled):hover .radio-container input ~ .radio-checkmark {
        border: 1.5px solid #fff;
    }

    .radio-container:hover input ~ .radio-checkmark {
        background-color: transparent; // 重置样式
    }

    .radio-container input:disabled ~ .radio-checkmark {
        background-color: #ccc; // 加强权重 refer radio.scss
    }

    .qTTOddsTableCollapse &{
        height: 36px;
    }
}

.radio-button-set-item.radio-button-set-item-checked {
    background-color: #DBE6EF;
}

.radio-button-set-item:not(.radio-button-set-item-checked, .radio-button-set-item-disabled):hover {
    background-color: #173E96;
    color: white;
}

.radio-button-set-method-S {
    grid-column: 1 / 3;
}

.radio-button-set-method-M {
    grid-column: 3 / 5;
}

.radio-button-set-method-B {
    grid-column: 5 / 7;
}

.radio-button-set-method-BM {
    grid-column: 1 / 4;
    // width: 105%;
}

.radio-button-set-method-MB {
    grid-column: 4 / 7;
}
//.TT .radio-row > *:not(:first-child) {
//    margin-left: 8px;
//}
.ttqp-btn,
.TT .method-title {
    // padding: 12px 0px 0px 0px;
    // line-height: 18px;
    // font-weight: bold;
    @include H4()
}
.ttqp-btn{
    color: #122c68;
}
.ttqp-row {
    // padding: 12px 0px 0px 0px;
    white-space: nowrap;
    visibility: hidden;
    @include H4()
}
.ttqp-button{
    width: 40px;
    height: 28px;
    background-color: #6176a0;
    border-radius: 14px;
    padding: 0;
    margin-right: 4px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.ttqp-icon {
    background-image: url(../../info/Include/images/ttqp.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 16px;
    // margin: 8px 0px 0px 0px;
    border: 0;
}


.ttqp-addslip {
    // padding-top: 4px;
    .AddToSlip {
        margin-right: 0;
    }
}

.QTT .qtt-qp-button-set {
    display: grid;
    grid-template-columns: 64% 12% 12% 12%;
    width: 100%;
    height: 44px;
    align-items: center;
    background-color: #f4f4f4;
}

.QTT .qtt-qp-button-set .method-title {
    padding-left: 12px;
}

.QTT .qtt-qp-button-set .randomBtn {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #122c68;
    font-weight: bold;
    border: 1.5px solid #122C68;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #122c68;
        color: #ffffff;
    }
}

.JTCOMBO .rc-jtc-allup-dropdown {
    display: none;
}

.iwn-odds-table,
.qin-odds-table {
    display: table;
    width: 100%;
    background-color: #fff;
}

.iwn-odds-table-header :first-child {
    // border-radius: 4px 0 0 0;
    position: sticky;
    left: 0;
}

.iwn-odds-table-header > :last-child {
    border-radius: 0 4px 0 0;
}

.iwn-odds-table .iwn-odds-table-row:last-of-type {
    border-radius: 0 0 4px 4px;
}

.iwn-odds-table .iwn-odds-table-row:last-of-type :first-child {
    border-radius: 0 0 0 4px;
}

.iwn-odds-table .iwn-odds-table-row:last-of-type :last-child {
    border-radius: 0 0 4px 0;
}

.iwn-odds-table .iwn-odds-table-header > td {
    // border: 0 !important; SQ0248-2042
    background-color: #122c68;
    color: #fff;
}

.qin-odds-table-header > td {
    border-left: 1px;

    background-color: #6176a0;
    color: #fff !important;
}

.qin-odds-table-header > .qin-odds-table-title {
    color: white;
    text-align: center;
    vertical-align: middle;
    height: 26px;
    border: 0;
    /* width: 85px; */
    position: sticky;
    left: 0;
    background-color: #122c68;
    border-right: solid 1px #d6d6d6;

}

.qin-odds-table .en-title-QPL > td {
    height: 47px;
}

.iwn-odds-table-header .iwn-odds-table-title > div {
    min-height: 26px;
    display: flex;
    // min-width: 65px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    white-space: nowrap;
}

.qin-odds-table-header .qin-odds-table-title > div {
    display: flex;
    justify-content: flex-start;
    padding: 0 8px;
    align-items: center;
    text-align: left;
    height: 100%;
    line-height: 18px;
    width: 100%;
    /* padding-right: 12px; */
}

// .qin-odds-table-header .qin-odds-table-title .wpqLabelWidth {
//     width: 77px;
// }

.iwn-odds-table-container .left-number-box {
    display: none;
}

.iwn-odds-table .left-number {
    background-color: #6176a0;
    color: #fff;
    // display: flex;
    // min-height: 26px;
    height: 26px;
    justify-content: center;
    border-right: 0;
    align-items: center;
    position: sticky;
    left: 0;
    z-index: 1;
}

.qin-odds-table .left-number,
.qin-odds-table .right-number,
.qin-odds-table .center-number {
    background-color: #6176a0;
    color: #fff;
    min-height: 26px;
    justify-content: center;
    border-right: 1px solid #d6d6d6;

    align-items: center;
}

.qin-odds-table-page3 {
    width: 41.176% !important;
}

.pagination-box {
    margin: 5px;
    justify-content: flex-end;
}

.pagination-box .active {
    /* background-color: #122C68 !important;
    color: #fff !important; */

    text-decoration-line: underline;
}

.pagination-box .arrow-small {
    border-color: #122c68;
}

.pagination-box .disable {
    /* background-color: #122C68 !important;
    color: #fff !important; */
    background-color: #b1b1b1 !important;
    color: white;
}

.pagination-box .disable .arrow-small {
    border-color: #ffffff;
}

.pagination-box > div {
    cursor: pointer;
    border: unset;
    text-align: center;
    line-height: 24px;
    background-color: unset;
    width: 24px;
    height: 24px;
    margin: 3px;
    color: #122c68;
    .arrow-small {
        margin-bottom: 2px;
    }
    /* border-bottom: #ccc 1px solid;
    border-right: #cfcfcf 1px solid; */
}

.pagination-box > div:first-child {
    // padding-left: 3px;
}

.pagination-box > div:last-child {
    padding-left: 1px;
}

.pagination-box > div:first-child,
.pagination-box > div:last-child {
    text-decoration-line: unset;
    // border: 1px solid #d6d6d6;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-box > div:hover {
    /* background-color: #6176A0;
    color: #fff; */
    cursor: pointer;
}

.wpq-odds-table-top20-box,
.wpq-odds-table-top10-box {
    width: 100%;
}

.wpq-odds-table-top20-box > header,
.wpq-odds-table-top10-box > header {
    height: 30px;
    background-color: #122c68 !important;
    line-height: 30px;
    padding-left: 9px;
    font-weight: bold;
    border-radius: 0;
    color: #fff;
}

.wpq-odds-table-top20,
.wpq-odds-table-top10 {
    width: 100%;
    background-color: #fff;
    display: table;
    box-shadow: var(--box-shadow);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.wpq-odds-table-top20 .wpq-odds-table-row,
.wpq-odds-table-top10 .wpq-odds-table-row {
    display: table-row;
    height: 26px;
}

.wpq-odds-table-top20 .wpq-odds-table-row td:nth-last-child(1),
.wpq-odds-table-top10 .wpq-odds-table-row td:nth-last-child(1) {
    border-right: 0;
}

.wpq-odds-table-top20 .sec,
.wpq-odds-table-top10 .sec {
    background-color: #f4f4f4;
}

.wpq-odds-table-top20 .wpq-odds-table-cell,
.wpq-odds-table-top10 .wpq-odds-table-cell {
    display: table-cell;
    border-right: 1px solid #d6d6d6;

}

.wpq-odds-table-top20 .wpq-odds-table-cell:nth-last-child(1) {
    border: 0;
}

.wpq-odds-table-top10 .wpq-odds-table-cell {
    width: 25%;
    height: 37px;
}

.wpq-odds-table-cell > div {
    display: flex;
    margin: 6px 5px 5px 5px;
    justify-content: space-between;
}

.wpq-odds-table-cell > div > div {
    text-align: left;
    line-height: 26px;
}

.wpq-odds-table-cell .cell-label {
    // width: 70%;
    padding-left: 8%;
    font-weight: bold;
    white-space: nowrap;
}

.wpq-odds-table-cell .cell-value {
    text-align: center;
    background-color: #dbe6ef;
    color: #122c68;
    min-width: 30%;
    padding: 0 2px;
    font-weight: bold;
    border-radius: 4px;
}

.wpq-odds-table-top10-title > td {
    border-bottom: 1px solid #d6d6d6;

    padding: 3px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}

.iwn-odds-table .sec,
.qin-odds-table .sec {
    background-color: #f4f4f4;
}

.iwn-odds-table > tr,
.qin-odds-table > tr {
    display: table-row;
}


.odds-table-display-switch {
    display: none;
}

.iwn-odds-table > tr > td:nth-last-child(1),
.qin-odds-table > tr > td:nth-last-child(1) {
    border-right: 0;
}

.iwn-odds-table > tr > td,
.qin-odds-table > tr > td {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    // color: #a3a3a3;
    color: #122c68;
    min-width: 26px;
    min-height: 26px;
    border-bottom: solid 1px #d6d6d6;
    border-right: solid 1px #d6d6d6;

    &.disabled{
        opacity: 0.7;
        color: #a3a3a3;
    }
}


.iwn-odds-table > tr > td.favourite,
.qin-odds-table > tr > td.favourite {
    background: #f03939;
    color: #fff;
}

.iwn-odds-table > tr > td.drop20,
.qin-odds-table > tr > td.drop20 {
    background: #2aa217;
    color: #fff;
}

.iwn-odds-table > tr > td.drop50,
.qin-odds-table > tr > td.drop50 {
    background: #993300;
    color: #fff;
}

.table-odds:hover {
    text-decoration: underline;
    .disabled > &{
        text-decoration: none;
    }
}

.qin-odds-table > tr > td {
    height: 26px;
}

.iwn-odds-table-pagination,
.qin-odds-table-pagination {
    justify-content: flex-end;
}

.qin-odds-table-container {
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
    height: auto !important;
}

.iwn-odds-table-container .iwn-odds-table-pagination .active,
.qin-odds-table-container .qin-odds-table-pagination .active {
    background-color: #122c68;
    color: #fff;
}

.iwn-odds-table-pagination > div,
.qin-odds-table-pagination > div {
    margin: 5px 0 5px 10px;
    height: 24px;
    width: 24px;
    background-color: #f4f4f4;
    line-height: 24px;
    text-align: center;
    border: 1px solid #ccc;

}

.iwn-odds-table-pagination > div:hover,
.qin-odds-table-pagination > div:hover {
    background-color: #6176a0;
    color: #fff;
}

.live-racing-icon {
    margin-right: 25px;
}

.JKC .jtcinfo .allrace {
    width: 10;
}

#block-ui-wrapper.active {
    width: 100vw;
    height: 100vh;
    content: '';
    position: fixed;
    display: block;
    background: black;
    z-index: 1000;
    opacity: 0.3;
    inset: 0px;
    top: 0;
    left: 0;
}

.masonry-col > div:first-child, .masonry-col > td:first-child {
    flex: 1 !important;
    margin-right: 6px;
    // overflow-x: auto;
    width: calc(60px);
}

.HR .masonry-col > div:last-child {
    min-width: 300px;
    width: 40% !important;
    flex: 0 1 auto !important;
}

.HR .masonry-col > td:last-child {
    min-width: 260px;
    width: 40%;
    flex: 0 1 auto !important;
}

// .HOME_RESULTS .masonry-col > div:last-child {
//     width: 50%;
// }


.masonry-col-cwa > div:first-child {
    width: 40% !important;
    flex: 0 !important;
    margin-right: 12px;
}

.masonry-col-cwa > div:last-child {
    width: calc(60% - 12px);
}

.wpq-table-switch {
    width: 100%;
    justify-content: space-evenly;
    height: 40px;
    align-items: center;
}

.wpq-table-switch-m {
    display: none;
}

.number-ordinal {
    position: relative;
    top: -5px;
    font-size: 12px;
}

.meeting-info-investment-top {
    // box-shadow: var(--box-shadow);
    display: block;
    overflow: hidden;
}

.meeting-info-investment {
    display: grid;
    height: auto;
    background: #dbe6ef;
    padding: 4px 0 4px 12px;
    line-height: 20px;
    border-radius: 0 4px 4px;
    width: calc(100% + 1px);
    margin-right: -1px;
}

.meeting-info-ivestment-2x {
    grid-template-columns: 1fr 1fr;
    gap: 0 16px;
}

.meeting-info-ivestment-3x {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 0 12px;

}

.meeting-info-investment > div {
    text-align: left;
    display: inline-block;
    padding: 4px 0;
    border-right: 1px solid #b4b4b4;

}

.meeting-info-investment .label,
.meeting-info-investment .amount-blue {
    display: inline-block;
}

.rcOddsTable{
    TT, .DT, .SIXUP{
        .meeting-info-investment .label{
            font-size: 12px;
        }
    }
}

.meeting-info-investment > div:not(:first-child) {
    // border-left: 1px solid #b4b4b4;
    // padding-left: 12px;
}

.meeting-info-investment .amount-blue {
    color: #122c68;
}

.multi-leg-2x-table {
    margin-top: 12px;
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: 1fr 1fr;
    align-items: start;
}

.multi-leg-3x-table {
    margin-top: 12px;
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr);
    align-items: start;
    .swiper-slide{
        display: block;
        width: auto;
        text-align: left;
        background-color: transparent;
    }
    .swiper-slide-prev::after, .swiper-slide-next::after{
        content: none;
    }
}

.multi-leg-slide-btn-prev {
    display: none;
}

.multi-leg-slide-btn-next {
    display: none;
}

.rc-odds-enquiry .title {
    background: #122c68;
    color: white;
    height: 36px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 8px;
    display: flex;
    justify-content: space-between;
    @include font15();
}

.rc-odds-enquiry .content {
    background: white;
    display: grid;
    grid-template-columns: 34% 34% 30%;
    padding: 12px 8px;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: var(--box-shadow);
}

.rc-odds-enquiry .content > div:not(:last-child) {
    border-right: 1px solid #ededed;

    margin-right: 12px;
}

.rc-odds-enquiry .content .race-btn {
    cursor: pointer;
    width: 100%;
}

.rc-odds-enquiry .content > div > div {
    line-height: 20px;
}

.race-btn.dim:hover {
    // background: #6176a0;
}

.rc-odds-enquiry-m {
    display: none;
}

.QTTOddsTable,
.cwin-racecard-table {
    min-height: 40px;
}

.QTTOddsTable-m,
.cwin-racecard-table-m {
    display: none;
}

.qTTOddsTableCollapse.collapse,
.cwinRaceCardCollapse.collapse,
.collapseIconList.collapse {
    display: block;
}

.qTTOddsTableCollapse .header {
    display: flex;
    align-content: center;
    align-items: center;
    // justify-content: space-between;
    color: #333333;
    height: 36px;
    /* padding-left: 14px; */
    // padding-left: clamp(4px, 2%, 14px);
    // padding-right: clamp(4px, 2%, 14px);
    background-color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // gap: 12px;
    justify-content: space-between;
    overflow: hidden;
}

.qTTOddsTableCollapse .header > label {
    /* margin-right: 56px; */
}

.meeting-info-investment-amount {
    color: #122c68;
}

.jpt-remarks {
    margin: 6px;
}

.quartet-odds-table-top20,
.odds-table-investment {
    display: table;
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
}

.odds-table-investment {
    text-align: center;
}

.odds-table-investment > div:first-child {
    height: 36px;
}

.odds-table-investment .quartet-odds-table-cell div {
    justify-content: center;
}

.quartet-odds-table-row {
    display: table-row;
    width: 100%;
    background-color: white;
}

.quartet-odds-table-row:nth-child(even) {
    background-color: #f4f4f4;
}

.quartet-odds-table-cell {
    display: table-cell;
    height: 26px;
    vertical-align: middle;
}

.width2 {
    width: 50%;
}

.width3 {
    width: 33.33333%;
}

.width4 {
    width: 25%;
}

.quartet-odds-table-cell:not(:last-child) {
    border-right: 1px #cccbcb solid;

}

.quartet-odds-table-cell > div {
    display: flex;
    justify-content: space-between;
    margin: 6px 0px 5px 5px;
    padding-right: 18px;
}

.FF .quartet-odds-table-cell > div,
.QTT .quartet-odds-table-cell > div {
    padding-right: 4px;
}

.quartet-odds-table-cell .cell-label {
    padding: 6px 0px;
    white-space: nowrap;
}

.quartet-odds-table-cell .cell-value {
    min-width: 47px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    background: #dbe6ef;
    padding: 6px;
    @include font15();
    color: #122c68;
    font-weight: 500;
    &.disabled{
        color: #a3a3a3 !important;
        opacity: 0.7;
    }
}

.qTTOddsTableCollapse .radio-container *,
.qqTTOddsTableCollapse .radio-container * {
    cursor: pointer;
}

.quartet-odds-table-top20-box,
.quartet-odds-table-top10-box,
.odds-table-investment-box {
    margin-bottom: 14px;
}

.quartet-odds-table-top20-box header,
.quartet-odds-table-top10-box header,
.odds-table-investment-box header {
    background: #122c68;
    color: white;
    height: 36px;
    // border-top-left-radius: 4px;
    // border-top-right-radius: 4px;
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    @include font15();
    font-weight: 500;
    // border-radius: 4px 4px 0px 0px;
    box-shadow: var(--box-shadow);
}

.foslider {
    display: none;
}

.meeting-info-fo-topinfo-container {
    flex-basis: 100%;
}

.dividend-table-container {
    margin-bottom: 12px;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
}

.rcOddsTable .forescontainer {
    margin-bottom: 12px;
}

.rcOddsTable .forescontainer > header {
    height: 36px;
    color: #fff;
    @include font15();
    font-weight: bold;
    line-height: 36px;
    padding-left: 10px;
    background-color: #122c68;
    border-radius: 4px 4px 0 0;
}

.RESULTS .forestable {
    display: table;
    width: 100%;
}

.RESULTS .forestable .rc-odds-table-header {
    text-align: left;
    background-color: #ffffff;
    @include font11();
}

.RESULTS .forestable .rc-odds-row {
    @include font13();
    height: 32px;
    width: 100%;
    text-align: left;
}

.RESULTS .forecfootnote {
    text-align: end;
    color: #122c68;
    @include font13();
    font-weight: 500;
    clear: both;
    height: 32px;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 15px 8px;
    background-color: #ffffff;
    margin-bottom: 5px;
}

.RESULTS .forecfootnote > a {
    cursor: pointer;
    vertical-align: middle;
}

.RESULTS .fosubtitleoth.sec,
.RESULTS .forecfootnote.sec {
    background: #f4f4f4;
}

.RESULTS .spancol.sec {
    background-color: #beb4b4;
}

.RESULTS .resulttitle {
    @include font15();
    margin-top: 5px;
}

.RESULTS .subtitleother {
    white-space: nowrap;
    text-align: left;
}

.icon-container {
    background-color: #ffffff;
}

.qTTOddsTableCollapse .m-slide {
    display: none;
}

.qTTOddsTableCollapse .m-content {
    display: none;
}

.quartet-odds-table-top10-title .quartet-odds-table-cell {
    text-align: center;
}

.investmentGraph-label {
    text-align: right;
    margin-top: 12px;
    text-decoration-line: underline;
    color: #122c68;
    cursor: pointer;
}

.investment-graph-popup-content {
    color: #333;
    width: 800px;
    min-height: 200px;
    height: auto;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 3px 8px #333;
    max-width: 97vw;
}

.investmentGraph-modal .header {
    @include font15();
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #122c68;
    color: white;
    padding-left: 12px;
}

.investmentGraph-modal .content {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}

.odds-table-investmentGraph-box {
    width: 100%;
    max-height: 80vh;
    overflow: scroll;
    overflow-x: hidden;
}

.investmentGraph-odds-table-row {
    display: inline-grid;
    width: 100%;
    grid-template-columns: [first] auto [line2] 23% [line3] 23% [col4-start] 23% [five] 23% [end];
}

.investmentGraph-odds-table-row-qtt {
    display: inline-grid;
    width: 100%;
    grid-template-columns: [first] auto [line2] 18% [line3] 18% [col4-start] 18% [five] 18% [six] 18% [end];
}

.investmentGraph-odds-table-row:first-child,
.investmentGraph-odds-table-row-qtt:first-child {
    height: 36px;
}

.investmentGraph-odds-table-row:nth-child(even),
.investmentGraph-odds-table-row-qtt:nth-child(even) {
    background-color: #ececec;
}

.investmentGraph-odds-table-cell {
    text-align: center;
    padding: 8px 0px;
}

.investmentGraph-odds-table-cell:nth-child(2),
.investmentGraph-odds-table-cell.money {
    text-align: left;
    padding-left: 12px;
    word-break: break-word;
}

.investmentGraph-odds-table-cell > div:nth-child(2) {
    position: absolute;
}

.investmentGraph-odds-table-cell.money {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.investmentGraph-odds-table-row > div:not(:last-child),
.investmentGraph-odds-table-row-qtt > div:not(:last-child) {
    border-right: 1px #c9c8c8 solid;

}

.investmentGraph-odds-table-cell .char-bar {
    // width: 100px;
    height: 22px;
    background: #cccccc;
    display: inline-block;
    position: absolute;
}

.char-bar.top {
    background: #6176a0;
}

.investmentGraph-odds-table-cell div {
    padding-top: 3px;
    display: inline-block;
}

.banker-keypad {
    display: none;
}

.rc-odds-table-compact-race-allup {
    width: 100%;
    height: 36px;
    background-color: white;
    text-align: left;
    padding-left: 12px;
}

.rc-odds-table-compact-race-allup > div {
    width: calc(100% - 23px);
}

.rc-odds-table-compact-race-allup select {
    border-color: #333333;
    @include font13();
    color: #122c68;
    line-height: 18px;
    font-weight: 500;
}

.rc-cwa-all-head {
    display: flex;
    width: 100%;
    height: 44px;
    background: #122c68;
    border-radius: 4px 4px 0 0;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.rc-cwa-all-head div {
    padding-left: 16px;
}

.rc-cwa-all-table {
    display: grid;
    grid-template-columns: 12% 25% 25% 25% 13%;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
}

.rc-cwa-all-single-table {
    grid-column: span 5;
    background-color: #dee2e6;
}

.rc-cwa-all-single-table.hide {
    display: none;
}

.rc-cwa-all-table-header {
    @include font11();
    padding: 8px;
}

.rc-cwa-all-table > div {
    text-align: center;
    // line-height: 32px;
    min-height: 36px;
    &:not(.rc-cwa-all-expand){

    display: flex;
    align-items: center;
    justify-content: center;
    }
}

.rc-cwa-all-table .rc-checkbox.rc-odds .checkbox-container {
    float: unset;
    margin: 0px 4px 0px 0px;
}

.oddbg {
    background-color: #f4f4f4;
}

.evenbg {
    background-color: #ffffff;
}

.rc-cwa-all-table div.rc-cwa-all-expand {
    text-align: right;
    padding: 0px 16px 0px 0px;
    line-height: 24px;
}

.rc-cwa-all-table-button {
    color: #ecf0f1;
    background: #6176a0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    position: relative;
    padding: 0px;
}

.rc-cwa-all-table-button span {
    position: absolute;
    background: white;
    border-radius: 2px;
}

.rc-cwa-all-table-button .plus:first-child {
    top: 20%;
    bottom: 20%;
    width: 10%;
    left: 45%;
}

.rc-cwa-all-table-button span:last-child {
    left: 20%;
    right: 20%;
    height: 10%;
    top: 45%;
}

.rc-cwa-all-remarks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 0px 8px;
}

.rc-cwa-all-m {
    display: none;
}

.rc-cwa-all-table .cwin-table-container {
    text-align: left;
    line-height: initial;
    border-radius: 4px;
    width: 100%;
}

.rc-cwa-all-table .horseName,
.rc-cwa-all-table .cwin-table-comp {
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.CROSS_ALUP .rc-odds-table-header-m.oddbg {
    background: #f4f4f4;
}

.CROSS_ALUP div.oddbg {
    background: #ffffff;
}

.CROSS_ALUP div.evenbg {
    background: #f4f4f4;
}

.rc-odds-table-compact-race-header .WINOddsTable-m {
    display: none;
}

.CROSS_ALUP .rc-odds-table-compact {
    margin: 0px;
}

.rc-odds-table-compact-race-allup .slAllup-dd > button,
.rc-odds-table-compact-race-allup .slAllupSubItem > button {
    height: 29px;
    border: 1px solid #757575;

    border-radius: 2px;
    color: #122c68;
    line-height: 18px;
    font-weight: 500;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset;
}

.rc-odds-table-compact-race-allup .slAllup-dd .dd-arrow,
.rc-odds-table-compact-race-allup .slAllupSubItem .dd-arrow {
    padding: 3px;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show {
    inset: 3px -2px auto auto !important;
    width: 100%;
    padding: 0px;
    min-width: 90%;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show > a {
    padding: 8px;
    color: #333333;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show > a:hover {
    color: #6176a0;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show > a.disabled {
    color: #adb5bd;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show > a:first-child {
    background-color: #122c68;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.rc-odds-table-compact-race-allup .dropdown-menu.show .dropdown-item:before {
    border-color: transparent transparent #122c68;
}

.rc-odds-table-compact-race-allup .FCT-ddbtn {
    width: 40%;
}

.rc-odds-table-compact-race-allup > div:nth-child(2) {
    margin-left: 5px;
    width: 54%;
}

.MultiLegCssSlider {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    .rc-odds-table-main {
        min-width: 100vw !important;
        scroll-snap-align: center;
        scroll-snap-stop: always;
    }
}

.rc-odds-table-main {
    /* min-height: 500px; */
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
}

.iwn-odds-slide-container {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.bottom-inv-cal {
    margin-top: 10px;
}

.dblTableTop {
    display: none;
}

.dblTableBtm {
    display: block;
    margin-top: 12px;
}

.meeting-info .foTopInfo {
    display: block;
}

.foTopInfo-m {
    display: none;
}

.RESULTS .horseName,
.RESULTS .jocky,
.RESULTS .trainer {
    text-align: left;
    padding-left: 8px;
}

.racingTopChangesRow {
    cursor: pointer;
    margin: 4px 0px;
    &:hover{
        color: #173e96;
    }
}

.download-race-btn {
    @include font11();
}

.jackpotBg {
    display: flex;
    padding-left: 12px;
    height: 28px;
    align-items: center;
    background: #dbe6ef;
    width: 50%;
}

.jackpotWrap {
    display: flex;
    background: #dbe6ef;
}

@media only screen and (max-width: 768px) and (pointer: coarse) {
    .jackpotBg {
        display: flex;
        padding-left: 12px;
        height: 28px;
        align-items: center;
        background: #dbe6ef;
        width: 100%;
    }

    .jackpotWrap {
        display: block;
    }
}

.ch header.HOME .meeting-info-wplogo, .ch .ewinMain .meeting-info-wplogo {
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    background-image: url('../../info/Include/images/worldpool_ch.png');
    background-size: 110px 20px;
    background-repeat: no-repeat;
    background-position: center;
    width:110px;
    min-width: 110px;
    height:36px;
    cursor: pointer;
}

.en header.HOME .meeting-info-wplogo, .en .ewinMain .meeting-info-wplogo {
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    background-image: url('../../info/Include/images/worldpool_en.png');
    background-size: 110px 20px;
    background-repeat: no-repeat;
    background-position: center;
    width:110px;
    min-width: 110px;
    height:36px;
    cursor: pointer;
}

.ewinMain .meeting-info-wplogo {
    margin-left:12px;
}
.jackpotBg {
    display: flex;
    padding-left: 12px;
    height: 28px;
    align-items: center;
    background: #dbe6ef;
    width: 50%;
}

.jackpotWrap {
    display: flex;
    background: #dbe6ef;
}

@media only screen and (max-width: 768px) and (pointer: coarse) {

.jackpotBg {
    display: flex;
    padding-left: 12px;
    height: 28px;
    align-items: center;
    background: #dbe6ef;
    width: 100%;
}

.jackpotWrap {
    display: block;
}
}