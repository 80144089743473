.fbOddsTable section {
    .header, .match-row{
        .date{
            width: 128px;
        }
        .fb-id{
            width: 70px;
        }
        .tourn{
            width: 48px;
            padding: 8px 12px;
        }

        .live-info{
            width: 42px;
            text-align: center;
            flex-direction: column-reverse;
        }
        >.odds, .oddsLine{

            width: 28%;
        }
        .inplay-clock-con, .inplay{
            width: 148px;
        }
        .line {
            width: 70px;
        }
        &.HFT{
            .odds{
                width: 380px;
            }
        }
        &.CRS, &.FCS{
            .oddsLine, .odds{
                width: 258px;
            }
        }
    }


    .header{
        .fb-id, .tourn{
            margin: 0 auto;
            text-align: center;
            padding: 0 12px;
        }

        .team{
            flex: 1;
            // align-self: flex-end;
        }

        .h2h{
            width: 48px;
            align-self: flex-end;
        }
    }
    .tourn_es_icon{
        width: 20px;
        height: 20px;
        background-image: url(../../info/Include/images/early_settlement_icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 2px;
    }
    .match-row {
        .team{
            flex: 1;
            align-self: initial;
            height: auto;
        }

        .oddsLine {
            &.HIL, &.FHL, &.FCH, &.CHL {
                width: 202px;
            }
        }
        .image-H2H{
            justify-content: flex-start;
            align-self: center;
        }
    }
    &.TTG, &.HFT{
        .odds{
            width: 360px;
        }
        .inplay-clock-con,.inplay{
            width: 94px;
        }
        .date{
            width: 94px;
        }

        .fb-id{
            width: 62px;
        }

        .tourn{
            width: 40px;
            padding: 8px;
        }
    }
    .teamIconSmall{
        .teamIconState{
            display: flex;
            align-items: center;
            column-gap: 8px;
            min-width: 60px;
            > :nth-child(1) {
                order: 2;
            }
            > :nth-child(2) {
                order: 3;
            }
            > :nth-child(3) {
                order: 1;
            }
            > .icon-venue {
                order: 1;
            }
            // &>:nth-child(n):not(:last-child){
            //     margin-right: 8px;
            // }
        }
    }
}
.fbOddsTable {
    .SGA, .FGS, .MSP, .AGS, .LGS{
        .header{
            .h2h{
                width: 36px;
                padding:0 6px 0 0 !important;
            }
        }
    }
    .tvChannels{
        min-width: 32px;
        height: 14px;
        font-family: 'Noto Sans TC';
        font-size: 9px;
        font-weight: 700;
        color: #666666;
        border: 0.5px solid #333333;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
    }
    .tvChannelList{
        display: flex;
        flex-wrap: wrap;
        &>:nth-child(n):not(:last-child){
            margin-right: 8px;
        }
    }
    .tvChannelsContent{
        position: relative;
    }
    .FBTVChannelDialog{
        color: #333;
        display: block;
        text-align: left;
        width: 300px;
        position: absolute;
        right: -150px;
        top: 20px;
        background-color: #ffffff;
        padding: 0;
        height: auto;
        border-radius: 10px;
        box-shadow: 0px 3px 8px #333;
        z-index: 999;
        .tvChannelDialogList{
            width: 300px;
            padding: 0 12px;
            div{
                height: 30px;
                font-size: 13px;
                line-height: 14px;
                display: flex;
                align-items: center;
            }
            div:not(:first-child){
                border-top: 1px solid #ccc;
            }
        }
    }
}

@media screen and (min-width: 1441px) {

    .fbOddsTable section .match-row {

        .date{
            padding-right: 24px;
        }
        .fb-id{
            /* padding-left: 24px ; */
            /* padding-right: 24px ; */
            justify-content: center;
        }
        .team {
           padding: 8px 12px;
        }
        .tourn{
            padding: 8px 24px;
        }

    }

    .fbOddsTable section {
        .header, .match-row{
            .date{
                width: 140px;
            }
            .fb-id{
                width: 94px;
            }

            .tourn{
                width:72px;
                padding: 0 24px;
            }
            .live-info{
                width: 66px;
            }
            >.odds, >.oddsLine{

                width: 28%;
            }
            .inplay-clock-con, .inplay{
                width: 172px;
            }
        }
        &.HIL, &.FHL, &.FCH, &.CHL {
            .header{
                .odds{
                    width: 20%;
                }

                .line{
                    width: 70px;
                }
            }
            .match-row {
                .oddsLine {
                    width: calc(20% + 70px);
                }
            }
        }
        .header{
            .fb-id, .tourn{
                margin: 0 auto;
                text-align: center;
            }
            .h2h{
                width: 48px;
                align-self: flex-end;
            }
        }
        &.TTG, &.HFT{
            .odds{
                width: 36%;
            }
            .inplay-clock-con{
                width: 172px;
            }
            .date{
                width: 140px;
            }

            .fb-id{
                width: 94px;
            }

            .tourn{
                width: 72px;
            }
        }
        &.HFT{
            .odds{
                /* width: 50%; */
            }
            
            .inplay-clock-con{
                width: 102px;
            }
        }
    }
}


@media screen and (max-width: 1650px) and (min-width: 1280px) {
    .fbOddsTable section{
        .header, .match-row{
            .date{
                width: 94px;
                padding-right: 12px;
            }
            .fb-id{
                width: 70px;
                padding: 0 12px;
            }
            .tourn{
                width: 48px;
                padding: 0 12px;
            }
        }

        &.HIL, &.FHL, &.FCH, &.CHL {
            .header{
                .odds{
                    width: 18%;
                }
                .line{
                    width: 75px;
                }
            }
            .match-row {
                .oddsLine {
                    width: calc(18% + 75px);
                    grid-template-columns: 75px 1fr;
                }
            }
        }
        &.TTG, &.HFT{
            .odds{
                width: 360px;
            }
            .inplay-clock-con, .inplay{
                width: 94px;
            }
            .date{
                width: 94px;
            }

            .fb-id{
                width: 62px;
            }

            .tourn{
                width: 40px;
            }
        }
        &.HFT{
            .odds{
                // width: 385px;
            }
            .date{
                width: 128px;
            }

            .fb-id{
                width: 70px;
            }

            .tourn{
                width: 48px;
            }
        }
        &.INPLAY{
            .match-row, .header{
                .date{
                    width: 128px;
                    padding-right: 12px;
                }
                .fb-id{
                    width: 70px;
                    padding: 0 12px;
                }
                .tourn{
                    width: 48px;
                    padding: 0 12px;
                }

                .live-info{
                    width: 42px;
                    text-align: center;
                    padding:0 8px;
                }
                .odds{

                    width: 28%;
                }
                .inplay-clock{
                    padding: 8px 12px;
                }

            }
            .inplay-clock-con, .inplay{
                width: 104px;
                .inplay-clock{

                    padding: 8px 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .oddsCheckboxGrid {
        column-gap: 8px;
    }
}

@media screen and (max-width: 1400px) {
    .fbOddsTable section{
        .inplay-clock-con{
            width: 94px;
        }
        .match-info .inplay-clock-con{
            width: auto;
        }
        .inplay-clock{
            text-align: start;
        }
        &.TTG, &.HFT{
            .odds{
                width: 29%;
            }
            .match-row{
                align-items: initial;
                .odds{
                    // grid-template-columns: repeat(5, 1fr);
                    display: flex;
                    justify-items: center;
                    justify-content: center;
                    padding: 14px 12px;
                    flex-wrap: wrap;
                    height: auto;
                    gap: 14px 0;
                    }
                &>*:not(.odds){
                    min-height: 84px;
                    align-self:flex-start;
                }
            }
        }
        &.HFT {
            .odds{
                width: 240px;
                padding-left: 12px;
            }

        }
        &:not(.ALLODDS, .INPLAY_ALL, .LAST_ODDS, .MIXALLUP_DETAIL, .MIXALLUP_DETAIL_SINGLE) .oddsValue span {
            min-width: 40px;
            text-align: center;
            cursor: pointer;
        }
    }
    .fbOddsTable header.ALLODDS {
        .inplay-clock-con{
            width: 94px;
        }
        .inplay-clock{
            text-align: start;
        }
    }
}
@media screen and (max-width: 1279px) {
    .fbOddsTable section {
        .match-row, .header{
            >.date{
                width: 94px;
                padding-right: 8px;
            }
            .fb-id{
                width: 62px;
                padding-left: 8px !important;
                padding-right: 8px !important;
            }
            .tourn{
                width: 40px ;

            }
            .team{
                flex: 1;
                padding-right: 8px;
                padding-left: 8px;
            }
            .inplay-clock-con, .inplay{
                width: 94px;
            }

            .odds, .oddsLine{
                width: 25%;
            }

            .live-info{
                width: 34px ;
                min-width: 18px
            }

        }
        .match-row{
            .tourn{
                padding: 8px 8px !important;
            }
            .oddsLine {
                &.HIL, &.FHL, &.FCH, &.CHL {
                    width: 206px;
                    grid-template-columns: 62px 1fr;
                }
            }
        }

        &.HIL, &.FHL, &.FCH, &.CHL {
            .header{
                .odds{
                    width: 15%;
                }
                .line{
                    width: 50px;
                }
            }
            .match-row {
                .oddsLine {
                    width: calc(15% + 50px);
                    grid-template-columns: 50px 1fr;
                }
            }
        }

    }
    .fbOddsTable .INPLAY_ALL .oddsHFT,
    .fbOddsTable .ALLODDS .oddsHFT,
    .fbOddsTable .LAST_ODDS .oddsHFT,
    .fbOddsTable .MIXALLUP_DETAIL .oddsHFT {
        .oddsValue {
            column-gap: 2px;
        }
    }

}
